@import url(https://fonts.googleapis.com/css?family=Roboto);
  /* http://meyerweb.com/eric/tools/css/reset/
     v2.0-modified | 20110126
     License: none (public domain)
  */

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
}


/* make sure to set some focus styles for accessibility */
:focus {
  outline: 0;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type=search] {
  -webkit-appearance: none;
  -moz-appearance: none;
  box-sizing: content-box;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */

audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  max-width: 100%;
}

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */

audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */

[hidden] {
  display: none;
}

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */

html {
  font-size: 100%; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
  -ms-text-size-adjust: 100%; /* 2 */
}

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */

a:focus {
  outline: thin dotted;
}

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */

a:active,
a:hover {
  outline: 0;
}

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */

img {
  border: 0; /* 1 */
  -ms-interpolation-mode: bicubic; /* 2 */
}

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */

figure {
  margin: 0;
}

/**
 * Correct margin displayed oddly in IE 6/7.
 */

form {
  margin: 0;
}

/**
 * Define consistent border, margin, and padding.
 */

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */

legend {
  border: 0; /* 1 */
  padding: 0;
  white-space: normal; /* 2 */
  *margin-left: -7px; /* 3 */
}

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */

button,
input,
select,
textarea {
  font-size: 100%; /* 1 */
  margin: 0; /* 2 */
  vertical-align: baseline; /* 3 */
  *vertical-align: middle; /* 3 */
}

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */

button,
input {
  line-height: normal;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */

button,
select {
  text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */

button,
html input[type="button"], /* 1 */
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button; /* 2 */
  cursor: pointer; /* 3 */
  *overflow: visible;  /* 4 */
}

/**
 * Re-set default cursor for disabled elements.
 */

button[disabled],
html input[disabled] {
  cursor: default;
}

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
  *height: 13px; /* 3 */
  *width: 13px; /* 3 */
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */

input[type="search"] {
  -webkit-appearance: textfield; /* 1 */ /* 2 */
  box-sizing: content-box;
}

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * Remove inner padding and border in Firefox 3+.
 */

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */

textarea {
  overflow: auto; /* 1 */
  vertical-align: top; /* 2 */
}

/**
 * Remove most spacing between table cells.
 */

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html,
button,
input,
select,
textarea {
  color: #222;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

img {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}
* {
  font-family: 'Roboto', sans-serif !important;
}

html {
  font-size: 16px;
}

@media (max-width: 320px) {
  html {
    font-size: 14px;
  }
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media (min-width: 561px) {
  .content {
    max-width: 560px;
    margin: 0 auto;
  }
}

.logoHeader {
  background: #fff url(/static/media/logo_small.169c8d4c.svg) center no-repeat;
  background-size: contain;
  text-align: center;
  width: 72px;
  height: 72px;
  margin-bottom: 2rem;
}

.flexContent.center {
   justify-content: center;
 }
.flexContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 1em;
  overflow: auto;
}

.switch {
  display: flex;
  justify-content: center;
  padding: 1em 0.5em;
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
}
.switch__button {
  background: #ffffff;
  color: #F15A29;
  border: 1px solid #F15A29;
  font-size: 0.875em;
  padding: 0.25em;
  width: 50%;
  word-break: break-word;
}
.switch__button:first-of-type {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.switch__button:last-of-type {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.switch__button.active {
  background: #F15A29;
  color: #ffffff;
}
@media (max-width: 290px) {
  .switch__button {
    font-size: 0.75em;
  }
}
.tastamatOpener__row {
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding: 0.875em 0;
}
.tastamatOpener__list {
   list-style: decimal;
   padding-left: 16px;
 }
.tastamatOpener__title, .tastamatOpener__title--black, .tastamatOpener__title--grey, .tastamatOpener__title--index {
  font-size: 1em;
  margin-bottom: 0.5em;
}
.tastamatOpener__title--grey {
  color: #808080;
  text-transform: uppercase;
}
.tastamatOpener__title--index {
  font-size: 1.2em;
  color: #808080;
  text-transform: uppercase;
}
.tastamatOpener__title--black {
  color: #000000;
}
.tastamatOpener__text, .tastamatOpener__text--blue, .tastamatOpener__text--blueSmall, .tastamatOpener__text--dark, .tastamatOpener__text--grey {
  font-size: 0.75em;
  line-height: 1.25em;
}
.tastamatOpener__text--grey {
  color: #808080;
}
.tastamatOpener__text--dark {
  color: #414141;
}
.tastamatOpener__text--dark.paid {
  list-style: none;
  position: relative;
  color: #35a518;
}
.tastamatOpener__text--dark.paid:before {
  content: "✓";
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: calc(50% - 0.5em);
  left: -16px;
  width: 1em;
  height: 1em;
  font-size: 1em;
  font-weight: 700;
  color: #35a518;
  border-radius: 50%;
}
.tastamatOpener__text--blueSmall {
  color: #6B78BB;
  font-weight: 700;
}
.tastamatOpener__text--blue {
  color: #6B78BB;
}
.tastamatOpener__text--blue:before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 4px;
  background: url(/static/media/phone.359f97be.svg) no-repeat;
  background-size: contain;
}
.tastamatOpener__partner {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 1rem;
  border: 1px solid rgba(128, 128, 128, 0.5);
}
.tastamatOpener__flexRow, .tastamatOpener__flexRow--middlePadding, .tastamatOpener__flexRow--smallPadding {
  display: flex;
  align-items: center;
}
.tastamatOpener__flexRow--smallPadding {
  padding: 0.25rem 0;
}
.tastamatOpener__flexRow--middlePadding {
  padding: 0.5rem 0;
}

.loader{position:absolute;left:50%;top:50%;z-index:9;margin:-30px 0 0 -30px;border:8px solid #ff78782e;border-left:8px solid #db2000;border-top:8px solid #db2000;border-radius:50%;width:60px;height:60px;-webkit-animation:spin 1s linear infinite;animation:spin 1s linear infinite}@-webkit-keyframes spin{0%{transform:rotate(0deg)}100%{transform:rotate(360deg)}}@keyframes spin{0%{transform:rotate(0deg)}100%{transform:rotate(360deg)}}

